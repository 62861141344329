const eventTypes = {
  activities: {
    ACTIVITY_SELECTED: 'Activity Selected',
  },
  billing: {
    ADD_PAYMENT_METHOD_STARTED: 'Add Payment method started',
    ADD_PAYMENT_METHOD_COMPLETED: 'Add Payment method completed',
    ADD_PAYMENT_METHOD_FAILED: 'Add Payment method failed',
    SUBSCRIBE_TO_PLAN_REQUEST: 'Subscribe to plan request',
    UPDATE_PLAN_REQUEST: 'Update plan request',
    MANAGE_PLAN: 'Manage Plan',
    UPGRADE_TO_ENTERPRISE_REQUEST: 'Upgrade to enterprise request',
    CANCEL_SUBSCRIPTION_REQUEST: 'Cancel subscription request',
  },
  comments: {
    COMMENT_CREATED: 'Comment Created',
    COMMENT_DELETED: 'Comment Deleted',
    COMMENT_EDITED: 'Comment Edited',
    COMMENT_REACTED: 'Comment Reacted',
    COMMENT_REACTION_REMOVED: 'Comment Reaction Removed',
    COMMENT_REPLIED: 'Comment Replied',
    COMMENT_UPDATED: 'Comment Updated',
    COMMENT_RESOLVED: 'Comment Resolved',
    COMMENT_REOPEN: 'Comment Reopen',
  },
  editors: {
    EDITOR_SLASH_MENU_OPENED: 'Editor / Menu Opened',
    TABLE_ADDED: 'Table Added',
    MENTION_ADDED: 'Mention Added',
    HEADING_ADDED: 'Heading Added',
    EMBED_ADDED: 'Embed Added',
    DIVIDER_ADDED: 'Divider Added',
    FIGMA_ADDED: 'Figma Added',
    LOOM_ADDED: 'Loom Added',
    YOUTUBE_ADDED: 'Youtube Added',
    VIMEO_ADDED: 'Vimeo Added',
    ME_ADDED: 'Me Added',
    HINT_ADDED: 'Hint Added',
    INVITE_USER_ADDED: 'Invite User Added',
    CREATE_PAGE_ADDED: 'Create Page Added',
    CREATE_TASK_ADDED: 'Create Task Added',
    IMPORT_TASKS_ADDED: 'Import Tasks Added',
    IMAGE_ADDED: 'Image Added',
    NUMBERED_LIST_ADDED: 'Numbered List Added',
    BULLET_LIST_ADDED: 'Bullet List Added',
    CODE_SNIPPET_ADDED: 'Code Snippet Added',
    QUOTE_ADDED: 'Quote Added',
    BOLD_ADDED: 'Bold Added',
    ITALIC_ADDED: 'Italic Added',
    UNDERLINE_ADDED: 'Underline Added',
    PARAGRAPH_ADDED: 'Paragraph Added',
  },
  examples: {
    EXAMPLE_MODAL_OPENED: 'Example Modal Opened',
    EXAMPLE_SLIDE_CHANGED: 'Example Slide Changed',
    EXAMPLE_CREATE_CLICKED: 'Example Create Clicked',
  },
  highlights: {
    HIHGLIGHT_SELECTED: 'Highlight Selected',
  },
  integrations: {
    INTEGRATION_CONNECTED: 'Integration Connected',
    INTEGRATION_DISCONNECTED: 'Integration Disconnected',
    INTEGRATION_MENU_SELECTED: 'Integrations Menu Selected',
    INTEGRATION_SELECTED: 'Integration Selected',
    INTEGRATION_STARTED: 'Integration Started',
    RECENT_SEARCHES_SELECTED: 'Recent Searches Selected',
    SEARCH_QUERIED: 'Search Queried',
    SEARCH_SELECTED: 'Search Selected',
    SLACK_CHANNEL_ADDED: 'Slack Channel Added',
  },
  intent: {
    INTENT_SELECTED: 'Intent Selected',
  },
  lifecycles: {
    STATUS_CHANGED: 'Status Changed',
  },
  login: {
    VISIT_LOGIN_PAGE: 'Visit Login Page',
  },
  measurements: {
    METRIC_UPDATED: 'Metric Updated',
    METRIC_CREATED: 'Metric Created',
    MEASUREMENT_UPDATED: 'Measurement Updated',
  },
  members: {
    INVITED_TO_ORG: 'Invited to Org',
    ADDED_TO_TEAM: 'Added to team',
    ADDED_TO_ITEM: 'Added to item',
    INVITED_TO_TEAM: 'Invited to team',
    INVITED_TO_ITEM: 'Invited to item',
    INVITED_TO_QATALOG: 'Invited to Qatalog',
    ASSIGNED_AS_OWNER: 'Aassigned as owner',
  },
  mentions: {
    MENTION_ADDED: 'Mention Added',
  },
  modules: {
    ITEM_DASHBOARD_VISITED: 'Item Dashboard Visited',
    ITEM_DASHBOARD_FILTERED: 'Item Dashboard Filtered',
    ITEM_DASHBOARD_SORTED: 'Item Dashboard Sorted',
    ITEM_DASHBOARD_LAYOUT_CHANGED: 'Item Dashboard Layout Changed',
    ITEM_DASHBOARD_QUICK_VIEW_CREATED: 'Item Dashboard Quick View Created',
    TIMELINE_PHASE_CREATED: 'Timeline Phase Created',
    TIMELINE_PHASE_DELETED: 'Timeline Phase Deleted',
    TIMELINE_PHASE_UPDATED: 'Timeline Phase Updated',
    ITEM_CREATED: 'Item Created',
    ITEM_DELETED: 'Item Deleted',
    ITEM_ARCHIVED: 'Item Archived',
    ITEM_EXTERNALLY_SHARED: 'Item Externally Shared',
    ITEM_PINNED: 'Item Pinned',
    ITEM_UNPINNED: 'Item Unpinned',
  },
  notifications: {
    NOTIFICATIONS_OPENED: 'Notifications Opened',
    NOTIFICATION_SELECTED: 'Notification Selected',
    NOTIFICATION_SETTINGS_UPDATED: 'Notification Settings Updated',
  },
  org: {
    ONBOARDING_CALL_CLICKED: 'Onboarding Call Clicked',
    ONBOARDING_CALL_SCHEDULED: 'Onboarding Call Scheduled',
  },
  pages: {
    PAGE_CREATED: 'Page Created',
    PAGE_DELETED: 'Page Deleted',
    PAGE_DRAFTED: 'Page Drafted',
    PAGE_EDITED: 'Page Edited',
    PAGE_STARTED: 'Page Started',
    PAGE_UPDATED: 'Page Updated',
    PAGE_VISITED: 'Page Visited',
    PAGE_COVER_IMAGE_SET: 'Page Cover Image Set',
    PAGE_COVER_IMAGE_DELETE: 'Page Cover Image Delete',
    PAGE_ICON_SET: 'Page Icon Set',
    PAGE_COMMENT_CREATED: 'Page Comment Created',
    PAGE_TEMPLATE_CREATED: 'Page Template Created',
  },
  people: {
    INVITE_SENT: 'Invite Sent',
    INVITE_STARTED: 'Invite Started',
    KUDOS_BUTTON_CLICKED: 'Kudos Button Clicked',
    KUDOS_SENT: 'Kudos Sent',
    KUDOS_DELETED: 'Kudos Deleted',
    KUDOS_THANKED: 'Kudos Thanked',
    PROFILE_EDITED: 'Profile Edited',
    PROFILE_SELECTED: 'Profile Selected',
    PROFILE_SIGNED_OUT: 'Profile Signed Out',
    PROFILE_UPDATED: 'Profile Updated',
    PROFILE_VIEWED_OTHER: 'Profile Viewed Other',
    PROFILE_VIEWED_OWN: 'Profile Viewed Own',
  },
  posts: {
    POST_COMMENTED: 'Post Commented',
    POST_CREATED: 'Post Created',
    POST_PUBLISHED: 'Post Published',
    POST_DELETED: 'Post Deleted',
    POST_DRAFTED: 'Post Drafted',
    POST_EDITED: 'Post Edited',
    POST_REACTED: 'Post Reacted',
    POST_REACTION_REMOVED: 'Post Reaction Removed',
    POST_VISITED: 'Post Visited',
    POST_SHARED: 'Post Shared',
    POST_STARTED: 'Post Started',
    POST_UPDATED: 'Post Updated',
    POST_COMMENT_CREATED: 'Post Comment Created',
    POST_TEMPLATE_CREATED: 'Post Template Created',
  },
  previews: {
    QUICK_PREVIEW_OPENED: 'Quick Preview Opened',
  },
  pulse: {
    PULSE_CLOSED: 'Pulse Closed',
    PULSE_LIST_ITEM_SELECTED: 'Pulse List Item Selected',
    PULSE_OPENED: 'Pulse Opened',
    PULSE_TAB_CHANGED: 'Pulse Tab Changed',
  },
  resources: {
    RESOURCE_CREATED: 'Resource Created',
    RESOURCE_DELETED: 'Resource Deleted',
    RESOURCE_EDITED: 'Resource Edited',
    RESOURCE_LINK_COPIED: 'Resource copied',
    RESOURCE_SELECTED: 'Resource Selected',
    RESOURCE_STARTED: 'Resource Started',
    RESOURCE_UPDATED: 'Resource Updated',
  },
  search: {
    AI_SEARCH_QUERIED: 'AI search queried',
    ANSWER_RETURNED: 'Answer returned',
    ANSWER_NOT_RETURNED: 'Answer not returned',
    SEARCH_SOURCES_SUBMITTED: 'Search sources submitted',
    SEARCH_ITEMS_SUBMITTED: 'Search items submitted',
    SUGGESTED_ACTION_CLICKED: 'Suggested action clicked',
    SUGGESTED_QUESTION_CLICKED: 'Suggested question clicked',
    FOLLOW_UP_QUESTION_CLICKED: 'Follow up question clicked',
    LOCKED_SEARCH_CLICKED: 'Clicked on Search with no Integrations Connected',
    SEARCH_FOCUSED: 'Search Focused',
    SEARCH_PAGINATE: 'Search Paginate',
    SEARCH_QUERIED: 'Search Queried',
    SEARCH_RESULT_SELECTED: 'Search Result Selected',
    SEARCH_INTEGRATION_CHANGED: 'Search Integration Changed',
    SEARCH_FILTER_CHANGED: 'Search Filter Changed',
    SEARCH_RELEVANCE_UPDATED: 'Search Relavance Updated',
    SEARCH_WEBSOCKET_ERROR: 'Search Websocket Error',
    PRESEARCH_FILTERS_CHANGED: 'PreSearch Filters Changed',
    FEEDBACK_THUMBS_UP_CLICKED: 'Feedback Thumbs Up Clicked',
    FEEDBACK_THUMBS_DOWN_CLICKED: 'Feedback Thumbs Down Clicked',
  },
  settings: {
    FREE_TRIAL_CUSTOMIZE_QATALOG_CLICKED: 'Free Trial Customize Qatalog Clicked',
    FREE_TRIAL_UPGRADE_TO_PRO_CLICKED: 'Free Trial Upgrade to Pro Clicked',
    PLAN_PAGE_VIEWED: 'Plan Page Viewed',
    VISITED: 'Settings Visited',
    views: {
      'query-settings': {
        VISITED: 'Query Settings Visited',
      },
      'query-history': {
        VISITED: 'Query History Visited',
      },
      mappings: {
        VISITED: 'Mappings Visited',
      },
      connectors: {
        VISITED: 'Connectors Visited',
      },
      suggestions: {
        VISITED: 'Suggestions Visited',
      },
      'custom-sources': {
        VISITED: 'Custom Sources Visited',
      },
      'api-access': {
        VISITED: 'API Access Settings Visited',
      },
      feedback: {
        VISITED: 'Feedback Visited',
      },
      'about-organization': {
        VISITED: 'About Organization Visited',
      },
      'logos-and-colors': {
        VISITED: 'Logos and Colors Visited',
      },
      navigation: {
        VISITED: 'Navigation Visited',
      },
      'custom-domain': {
        VISITED: 'Custom Domain Visited',
      },
      'manage-users': {
        VISITED: 'Manage Users Visited',
      },
      'joining-this-account': {
        VISITED: 'Joining This Account Visited',
      },
      'external-sharing': {
        VISITED: 'External Sharing Visited',
      },
      'login-methods': {
        VISITED: 'Login Methods Visited',
      },
      'directory-sync': {
        VISITED: 'Directory Sync Visited',
      },
      modules: {
        VISITED: 'Modules Visited',
      },
      'system-library': {
        VISITED: 'System Library Visited',
      },
      templates: {
        VISITED: 'Templates Visited',
      },
      plan: {
        VISITED: 'Plan Visited',
      },
      'payment-methods': {
        VISITED: 'Payment Methods Visited',
      },
      'reset-account': {
        VISITED: 'Reset Account Visited',
      },
      'cancel-subscription': {
        VISITED: 'Cancel Subscription Visited',
      },
      profile: {
        VISITED: 'Profile Visited',
      },
      deactivate: {
        VISITED: 'Deactivate Visited',
      },
      notifications: {
        VISITED: 'Notifications Visited',
        DISABLED_ALL_NOTIFICATIONS: 'Disabled All Notifications',
      },
      'sign-out': {
        VISITED: 'Sign Out Visited',
      },
      'my-connections': {
        VISITED: 'My Connections Visited',
      },
      'disconnect-connectors': {
        VISITED: 'Disconnect connectors Visited',
      },
      'all-connections': {
        VISITED: 'All Connections Visited',
      },
      slack: {
        VISITED: 'Slack Settings Visited',
      },
    },
    ORG_DEACTIVATION_REQUESTED: 'Org Deactivation Requested',
    ORG_RESET_EXECUTED: 'Org Reset Executed',
    USER_ROLE_CHANGED: 'User Role Changed',
    USER_INVITED: 'User Invited',
    USER_DEACTIVATED: 'User Deactivated',
    INTEGRATION_BLOCKED: 'Integration Blocked',
    DIRECTORY_SYNC_ENABLED: 'Directory Sync Enabled',
    DIRECTORY_SYNC_DISABLED: 'Directory Sync Disabled',
    subscription: {
      CTA_LINKED_LEARN_MORE_PRICING_PAGE: 'CTA Linked: Learn More Pricing Page',
      CTA_CLICKED_UPGRADE_PRICING: 'CTA Clicked: Upgrade Pricing',
      CTA_CLICKED_DOWNGRADE_PRICING: 'CTA Clicked: Downgrade Pricing',
      SUBSCRIPTION_CANCELLED_REQUEST: 'Subscription Cancelled Request',
    },
    builders: {
      modules: {
        MODULE_SETTINGS_PAGE_VISITED: 'Module Settings Page Visited',
        MODULE_EDIT_DETAILS_VISITED: 'Module Edit Details Visited',
        CONNECTION_ADDED: 'Connection Added',
        CONNECTION_REMOVED: 'Connection Removed',
        FIELD_ADDED: 'Field Added',
        FIELD_REMOVED: 'Field Removed',
        MODULE_CREATED: 'Module Created',
        MODULE_ENABLED: 'Module Enabled',
        MODULE_DISABLED: 'Module Disabled',
      },
      lifecycles: {
        LIFECYCLE_ADDED: 'Lifecycle Added',
        LIFECYCLE_REMOVED: 'Lifecycle Removed',
        LIFECYCLE_STATUS_ADDED: 'Lifecycle Status Added',
        LIFECYCLE_STATUS_REMOVED: 'Lifecycle Status Removed',
      },
      pages: {
        PAGES_ADDED: 'Pages Added',
        PAGES_REMOVED: 'Pages Removed',
        PAGES_DEFAULT_ADDED: 'Pages Default Added',
        PAGES_DEFAULT_REMOVED: 'Pages Default Removed',
      },
      integrations: {
        INTEGRATION_ADDED: 'Integration Added',
        INTEGRATION_REMOVED: 'Integration Removed',
      },
      reminders: {
        REMINDERS_ADDED: 'Reminders Added',
        REMINDERS_REMOVED: 'Reminders Removed',
      },
      resources: {
        RESOURCES_ADDED: 'Resources Added',
        RESOURCES_REMOVED: 'Resources Removed',
        RESOURCES_DEFAULT_ADDED: 'Resources Default Added',
        RESOURCES_DEFAULT_REMOVED: 'Resources Default Removed',
      },
      threads: {
        THREADS_ADDED: 'Threads Added',
        THREADS_REMOVED: 'Threads Removed',
        THREADS_TOPIC_ADDED: 'Threads Topic Added',
        THREADS_TOPIC_REMOVED: 'Threads Topic Removed',
      },
      timelines: {
        TIMELINES_ADDED: 'Timelines Added',
        TIMELINES_REMOVED: 'Timelines Removed',
      },
      workflows: {
        WORKFLOWS_ADDED: 'Workflows Added',
        WORKFLOWS_REMOVED: 'Workflows Removed',
        WORKFLOWS_DEFAULT_ADDED: 'Workflows Default Added',
        WORKFLOWS_DEFAULT_REMOVED: 'Workflows Default Removed',
      },
      measurements: {
        MEASUREMENTS_ADDED: 'Measurements Added',
        MEASUREMENTS_REMOVED: 'Measurements Removed',
      },
      tasks: {
        TASKS_ADDED: 'Tasks Added',
        TASKS_REMOVED: 'Tasks Removed',
        TASKS_DEFAULT_ADDED: 'Tasks Default Added',
        TASKS_DEFAULT_REMOVED: 'Tasks Default Removed',
        TASKS_STATUS_ADDED: 'Tasks Status Added',
        TASKS_STATUS_REMOVED: 'Tasks Status Removed',
        SUBTASKS_DEFAULT_ADDED: 'Subtasks Default Added',
        SUBTASKS_DEFAULT_REMOVED: 'Subtasks Default Removed',
      },
    },
  },
  signup: {
    VISIT_TO_SIGNUP_PAGE: 'Visit To Signup Page',
    VISIT_TO_SIGNUP_PAGE_FOR_INDUSTRY_BUILD: 'Visit To Signup Page For Industry Build',
    CONTINUE_WITH_GOOGLE_CLICKED: 'Continue with Google Clicked',
    CONTINUE_WITH_MICROSOFT_CLICKED: 'Continue with Microsoft Clicked',
    CONTINUE_WITH_OKTA_CLICKED: 'Continue with Okta Clicked',
    CONTINUE_WITH_EMAIL_CLICKED: 'Continue with Email Clicked',
    VISIT_CONTINUE_WITH_EMAIL_PAGE: 'Visit Continue With Email Page',
    VISIT_CONTINUE_WITH_OKTA_PAGE: 'Visit Continue With Okta Page',
    VISIT_ORGANIZATIONS_PICKER_PAGE: 'Visit Organizations Picker Page',
    ORGANIZATIONS_PICKER_ACCEPT_JOIN_CTA_CLICKED: 'Organizations Picker Accept/Join CTA Clicked',
    VISIT_NAME_CONFIRMATION_PAGE: 'Visit Name Confirmation Page',
    VISIT_FREE_TRIAL_INSTANCE_TYPE_STEP: 'Visit Free Trial Instance Type Step Page',
    VISIT_TO_ORGANIZATION_NAME_PAGE: 'Visit To Organization Name Page',
    VISIT_WORKSPACE_PAGE_AFTER_ONBOARDING: 'Visit Workspace Page After Onboarding',
    SIGNUP_ONBOARDING_COMPLETED: 'Sign Up Onboarding Completed',
    SIGNUP_ONBOARDING_ERROR: 'Sign Up Onboarding Error',
    VISIT_FREE_TRIAL_PENDING_PAGE: 'Visit Free Trial Pending Page',
    FREE_TRIAL_CONNECT_DATA_SOURCES_CLICKED: 'Free Trial Connect Data Sources Clicked',
    FREE_TRIAL_ORG_DETAILS_SUBMITTED: 'Onboarding Org Details Submitted',
    FREE_TRIAL_SIGNUP_FORM_SUBMISSION_ERROR: 'Free Trial Signup Form Submission Error',
    FREE_TRIAL_INVITE_TEAM_PLANS_PAGE_CLICKED: 'Free Trial Invite Plans Page Clicked',
    FREE_TRIAL_INVITE_TEAM_SIDEBAR_CLICKED: 'Free Trial Invite Team Sidebar Clicked',
    FREE_TRIAL_INVITE_TEAM_HOMEPAGE_CLICKED: 'Free Trial Invite Team Homepage Clicked',
    VISIT_ONBOARDING_SETUP_PAGE: 'Visit Onboarding Setup Page',
    VISIT_FREE_TRIAL_SIGNUP_PAGE: 'Visit Free Trial Signup Step Page',
    FREE_TRIAL_CLICK_WATCH_A_DEMO: 'Free Trial Click on Watch a Demo',
    FREE_TRIAL_CLICK_WATCH_DEMO_ASK: 'Free Trial Click on Ask & Find Demo',
    FREE_TRIAL_CLICK_WATCH_DEMO_MANAGE_CONNECTIONS: 'Free Trial Click on Manage Connections Demo',
    FREE_TRIAL_CLICK_WATCH_DEMO_CUSTOMIZE_QATALOG: 'Free Trial Click on Customize Qatalog Demo',
  },
  signupV2: {
    VISIT_PROMPT_PAGE: 'Visit Prompt Page',
    PROMPT_SELECTED: 'Prompt Selected',
    PROMPT_SUBMITTED: 'Prompt Submitted',
    VISIT_PRODUCTS_PAGE: 'Visit Products Page',
    PRODUCT_SELECTED: 'Pre-defined Product Selected',
    VISIT_PRODUCT_PAGE: 'Visit Product Modules Page',
    VISIT_CUSTOM_SETUP_PAGE: 'Visit Custom Setup Page',
    CUSTOM_MODULE_ADDED: 'Custom Setup Module Added',
    CUSTOM_MODULE_RENAMED: 'Custom Setup Module Renamed',
    CUSTOM_MODULE_DELETED: 'Custom Setup Module Deleted',
    CUSTOM_MODULES_SUBMITTED: 'Custom Setup Modules Submitted',
    VISIT_CUSTOM_SETUP_REVIEW_PAGE: 'Visit Custom Setup Review Page',
    VISIT_BOOK_AN_ONBOARDING_SETUP_PAGE: 'Visit Book An Onboarding Setup Page',
    ONBOARDING_SETUP_BOOKED: 'Onboarding Setup Booked',
    ONBOARDING_SETUP_THANKYOU_INGRESSES_CLICKED: 'Onboarding Setup Thankyou Ingresses Clicked',
  },
  support: {
    HELP_CENTRE_CLICKED: 'Help Center Clicked',
    CONTACT_SUPPORT_CLICKED: 'Contact Support Clicked',
    UNSUPPORTED_BROWSER: 'Unsupported Browser',
  },
  tags: {
    TAGS_CREATED: 'Tags Created',
    TAGS_ADDED: 'Tags Added',
    TAGS_REMOVED: 'Tags Removed',
  },
  tasks: {
    TASK_CREATED: 'Task Created',
    TASK_UPDATED: 'Task Updated',
    TASK_DELETED: 'Task Deleted',
    TASK_REORDERED: 'Task Reordered',
    TASK_ASSIGNED: 'Task Assigned',
    TASK_COMPLETED: 'Task Completed',
    TASK_UNCOMPLETED: 'Task Uncompleted',
    TASK_SELECTED: 'Task Selected',
    TASK_VISITED: 'Task Visited',
    TASK_COMMENT_CREATED: 'Task Comment Created',
    // sub tasks
    SUB_TASK_CREATED: 'Subtask Created',
    SUB_TASK_UPDATED: 'Subtask Updated',
    SUB_TASK_DELETED: 'Subtask Deleted',
    SUB_TASK_REORDERED: 'Subtask Reordered',
  },
  teams: {
    STATUS_UPDATED: 'Status Updated',
    TEAM_CREATED: 'Team Created',
    TEAM_DELETED: 'Team Deleted',
    TEAM_EDITED: 'Team Edited',
    TEAM_SHARED: 'Team Shared',
    TEAM_STARTED: 'Team Started',
    TEAM_UPDATED: 'Team Updated',
    TEAM_PINNED: 'Team Pinned',
    TEAM_UNPINNED: 'Team Unpinned',
    TEAM_VIEWED: 'Team Viewed',
    MEMBER_INVITED: 'Member Invited',
  },
  templates: {
    TEMPLATE_CATEGORY_NEW_STARTED: 'Template Category New Started',
    TEMPLATE_CATEGORY_CREATED: 'Template Category Created',
    TEMPLATE_CATEGORY_DELETED: 'Template Category Deleted',
    TEMPLATE_STARTED: 'Template Started',
    TEMPLATE_EDITED: 'Template Edited',
    TEMPLATE_CREATED: 'Template Created',
    TEMPLATE_UPDATED: 'Template Updated',
    TEMPLATE_DELETED: 'Template Deleted',
    TEMPLATE_PLACEHOLDER_BLANK_OPTION_USED: 'Template Placeholder Blank Option Used',
    TEMPLATE_SELECTOR_OPENED: 'Template Selector Opened',
    TEMPLATE_USED: 'Template Selector Used',
    TEMPLATE_SELECTOR_CLOSED: 'Template Selector Closed',
  },
  threads: {
    THREAD_COMMENT_CREATED: 'Thread Comment Created',
    THREAD_CREATED: 'Thread Created',
    THREAD_DELETED: 'Thread Deleted',
    THREAD_EDITED: 'Thread Edited',
    THREAD_REACTED: 'Thread Reacted',
    THREAD_REACTION_REMOVED: 'Thread Reaction Removed',
    THREAD_UNRESOLVED: 'Thread Unresolved',
    THREAD_RESOLVED: 'Thread Resolved',
    THREAD_SELECTED: 'Thread Selected',
    THREAD_STARTED: 'Thread Started',
    THREAD_UPDATED: 'Thread Updated',
    THREADS_VISITED: 'Threads Visited',
    THREAD_TEMPLATE_CREATED: 'Thread Template Created',
  },
  triggers: {
    TRIGGER_CREATED: 'Trigger Created',
    TRIGGER_DELETED: 'Trigger Deleted',
    TRIGGER_EDITED: 'Trigger Edited',
    TRIGGER_SELECTED: 'Trigger Selected',
    TRIGGER_STEP_ADDED: 'Trigger Step Added',
    TRIGGER_STEP_DELETED: 'Trigger Step Deleted',
    TRIGGER_UPDATED: 'Trigger Updated',
    TRIGGER_PUBLISHED: 'Trigger Published',
    TRIGGER_DRAFT_DISCARDED: 'Trigger Draft Discarded',
    TRIGGER_DUPLICATED: 'Trigger Duplicated',
    TRIGGER_VIEWED: 'Trigger Viewed',
  },
  uploads: {
    csv: {
      IMPORT_CTA_CLICKED: 'Import CTA Clicked',
      IMPORT_EDIT_CTA_CLICKED: 'Import Edit CTA Clicked',
      IMPORT_STARTED: 'Import Started',
      IMPORT_COMPLETED: 'Import Completed',
      IMPORT_FAILURES_DOWNLOADED: 'Import Failures Downloaded',
      IMPORT_TEMPLATE_DOWNLOADED: 'Import Template Downloaded',
      IMPORT_REFERENCE_DOWNLOADED: 'Import Reference Downloaded',
      EXPORT_REFERENCE_ITEMS: 'Export Reference Items',
    },
  },
  workflows: {
    WORKFLOW_APPROVAL_COMPLETED: 'Workflow Approval Completed',
    WORKFLOW_APPROVAL_REQUESTED: 'Workflow Approval Requested',
    WORKFLOW_ASSIGNED: 'Workflow Assigned',
    WORKFLOW_STARTED: 'Workflow Started',
    WORKFLOW_CREATED: 'Workflow Created',
    WORKFLOW_DELETED: 'Workflow Deleted',
    WORKFLOW_EDITED: 'Workflow Edited',
    WORKFLOW_MOVED: 'Workflow Moved',
    WORKFLOW_SELECTED: 'Workflow Selected',
    WORKFLOW_SHARED: 'Workflow Shared',
    WORKFLOW_STEP_ADDED: 'Workflow Step Added',
    WORKFLOW_STEP_DELETED: 'Workflow Step Deleted',
    WORKFLOW_STEP_COMPLETED: 'Workflow Step Completed',
    WORKFLOW_UPDATED: 'Workflow Updated',
    WORKFLOW_PUBLISHED: 'Workflow Published',
    WORKFLOW_DRAFT_DISCARDED: 'Workflow Draft Discarded',
    WORKFLOW_DUPLICATED: 'Workflow Duplicated',
    WORKFLOW_VIEWED: 'Workflow Viewed',
    WORKFLOW_ASSIGNMENT_CANCELLED: 'Workflow Assignment Cancelled',
    WORKFLOW_ASSIGNMENT_VISITED: 'Workflow Assignment Visited',
    WORKFLOW_ASSIGNMENT_STARTED: 'Workflow Assignment Started',
    WORKFLOW_ASSIGNMENT_COMPLETED: 'Workflow Assignment Completed',
    WORKFLOW_ASSIGNMENT_UPDATED: 'Workflow Assignment Updated',
  },
  workspace: {
    HOMEPAGE_CARD_CREATED: 'Homepage Card Created',
    HOMEPAGE_CARD_DELETED: 'Homepage Card Deleted',
    HOMEPAGE_CARD_DETAILS_SELECTED: 'Homepage Card Details Selected',
    HOMEPAGE_CARD_REARRANGE: 'Homepage Card Rearranged',
    HOMEPAGE_CARD_SELECTED: 'Homepage Card Selected',
    HOMEPAGE_CARD_STARTED: 'Homepage Card Started',
    HOMEPAGE_CARD_TYPE_SELECTED: 'Homepage Card Type Selected',
    PIN_TO_DASHBOARD: 'Pin To Dashboard',
    HOMEPAGE_CCONNECTIONS_CLICKED: 'Homepage Add Connections Clicked',
  },
  trialExpired: {
    MESSAGE_EDITED: 'Request to upgrade message updated',
    MESSAGE_SENT: 'Request to upgrade message sent',
    BOOK_CALL_WITH_SALES: 'Book call with sales',
    UPGRADE_SUBSCRIPTION_STARTED: 'Upgrade subscription started',
    UPGRADE_SUBSCRIPTION_FAILED: 'Upgrade subscription failed',
    UPGRADE_SUBSCRIPTION_SUCCESSFUL: 'Upgrade subscription successful',
    COUNTRY_SELECTED: 'Country selected',
    CREDIT_CARD_INFO_STARTED: 'Credit card information started',
    CREDIT_CARD_INFO_COMPLETED: 'Credit card information completed',
    PLAN_SELECTED: 'Plan selected',
    INVOICE_EMAIL_STARTED: 'Invoice email started',
    INVOICE_EMAIL_COMPLETED: 'Invoice email completed',
  },
} as const;

export default eventTypes;

export const desplit = (str = '', delimiter: string | RegExp = '-', replacement: string = ' ') => {
  const replace = new RegExp(delimiter, 'g');
  return str.replace(replace, replacement);
};

export const desnake = (str: string = '', replacement = ' ') => desplit(str, '_', replacement);

export const dekebab = (str: string = '', replacement = ' ') => desplit(str, '-', replacement);

export const decamel = (str: string = '', replacement = ' ') =>
  str
    .replace(/([a-z\d])([A-Z])/g, `$1${replacement}$2`)
    .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, `$1${replacement}$2`)
    .toLowerCase();

export const sentence = (str: string = '') => str.replace(/^\w/, (c) => c.toUpperCase());

export const normalize = (str: string = '') => sentence(desplit(decamel(str), /[-_/./|]/g));

export const pascalize = (str: string = '') =>
  str
    .split(/[\W-_/]/)
    .map((part) => part.replace(/^\w/, (c) => c.toUpperCase()))
    .join('');

// Ref: https://stackoverflow.com/questions/4558437/programmatically-determine-whether-to-describe-an-object-with-a-or-an
export const indefiniteArticle = (
  subject: string = '',
  includeSubject: boolean = false,
): string => {
  enum Article {
    A = 'a',
    AN = 'an',
  }

  if (!subject) return Article.A;

  const render = (article: Article) => (includeSubject ? `${article} ${subject}` : article);

  let word: string;
  const match = /\w+/.exec(subject);
  if (match) {
    word = match[0];
  } else {
    return render(Article.AN);
  }

  const wordLower = word.toLowerCase();

  // H Words
  for (const hWord in ['honest', 'hour', 'hono']) {
    if (wordLower.startsWith(hWord)) return render(Article.AN);
  }

  // Single-letter Words
  if (wordLower.length === 1) {
    if ('aedhilmnorsx'.indexOf(wordLower) >= 0) {
      return render(Article.AN);
    } else {
      return render(Article.A);
    }
  }

  // Common Initialisms
  if (
    word.match(
      /(?!FJO|[HLMNS]Y.|RY[EO]|SQU|(F[LR]?|[HL]|MN?|N|RH?|S[CHKLMNPTVW]?|X(YL)?)[AEIOU])[FHLMNRSX][A-Z]/,
    )
  ) {
    return render(Article.AN);
  }

  // Vowel Exceptions
  for (const vowelException of [/^e[uw]/, /^onc?e\b/, /^uni([^nmd]|mo)/, /^u[bcfhjkqrst][aeiou]/]) {
    if (wordLower.match(vowelException)) return render(Article.A);
  }

  // Special Initialisms
  if (word.match(/^U[NK][AIEO]/)) {
    return render(Article.A);
  } else if (word === word.toUpperCase()) {
    if ('aedhilmnorsx'.includes(wordLower[0])) return render(Article.AN);
    else return render(Article.A);
  }

  // Common Vowel Words
  if ('aeiou'.includes(wordLower[0])) return render(Article.AN);

  // Y Word Exceptions
  if (wordLower.match(/^y(b[lor]|cl[ea]|fere|gg|p[ios]|rou|tt)/)) return render(Article.AN);

  return render(Article.A);
};

export const possessive = (subject: string = '') => {
  if (!subject.length) return subject;
  if (subject.endsWith('s')) return `${subject}'`;
  return `${subject}'s`;
};

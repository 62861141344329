import {
  createContext,
  FC,
  PropsWithChildren,
  Reducer,
  useCallback,
  useContext,
  useReducer,
  useRef,
} from 'react';
import { useMetadataState } from 'store/metadata';
import { csrfRefresh } from 'utils/csrfRefresh';

export interface WebsocketState {
  websocket: WebSocket | null;
}

export interface WebsocketAction {
  type: WebsocketActionType;
  websocket: WebSocket | null;
}

export interface WebsocketMessageOptions {
  skipLastMessage?: boolean;
  resetLastMessage?: boolean;
}

type ConnectOptions = {
  url?: string;
  enableHeartbeat?: boolean;
};

export interface WebsocketContextValue extends WebsocketState {
  connectWebsocket: (options?: ConnectOptions) => Promise<WebSocket | unknown>;
  sendMessage: (data: string, options?: WebsocketMessageOptions) => void;
  closeWebsocket: () => void;
  subscribeToMessages: (subscribers: (data: any, lastMessage: any) => void) => void;
}

enum WebsocketActionType {
  OPEN_WEB_SOCKET = 'openWebsocket',
  CLOSE_WEB_SOCKET = 'closeWebsocket',
}

const initialState: WebsocketState = {
  websocket: null,
};

const websocketReducer: Reducer<WebsocketState, WebsocketAction> = (state, action) => {
  switch (action.type) {
    case WebsocketActionType.OPEN_WEB_SOCKET:
      return {
        ...state,
        websocket: action.websocket,
      };

    case WebsocketActionType.CLOSE_WEB_SOCKET:
      return {
        ...state,
        websocket: null,
      };

    default:
      return state;
  }
};

export const WebsocketContext = createContext<WebsocketContextValue>({
  ...initialState,
} as WebsocketContextValue);

export const useWebsocket = () => useContext(WebsocketContext);

export const WebsocketProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer(websocketReducer, initialState);

  const [metadata] = useMetadataState();

  const wsRef = useRef<WebSocket>();
  const lastMessage = useRef<string>();
  const subscribersRef = useRef<
    Array<(data: string, lastMessage: React.MutableRefObject<string | undefined>) => void>
  >([]);

  const reconnectRef = useRef<NodeJS.Timeout>();
  const heartbeatRef = useRef<NodeJS.Timeout>();
  const readyRef = useRef<boolean>(false);
  const attemptCsrfRefreshRef = useRef<boolean>(true);

  const closeWebsocket = useCallback(() => {
    wsRef.current?.close();
    wsRef.current = undefined;
    subscribersRef.current = [];

    clearTimeout(reconnectRef.current);
    clearTimeout(heartbeatRef.current);

    dispatch({
      type: WebsocketActionType.CLOSE_WEB_SOCKET,
      websocket: null,
    });
  }, []);

  const sendMessage = useCallback((data: string, options?: WebsocketMessageOptions) => {
    if (wsRef.current?.readyState === 1) {
      wsRef.current?.send?.(data);
    }

    if (options?.resetLastMessage) {
      lastMessage.current = undefined;
    } else if (!options?.skipLastMessage) {
      lastMessage.current = data;
    }
  }, []);

  const heartbeat = useCallback(() => {
    sendMessage(JSON.stringify({ operation: 'ack', message: 'heartbeat' }), {
      skipLastMessage: true,
    });

    if (!!heartbeatRef.current) {
      clearTimeout(heartbeatRef.current);
    }

    // Retry in 5 seconds
    heartbeatRef.current = setTimeout(heartbeat, 5000);
  }, [sendMessage]);

  const connectWebsocket = useCallback(
    (options?: ConnectOptions) => {
      return new Promise((resolve, reject) => {
        const connectionUrl =
          options?.url ??
          `wss://${metadata.host}?csrf=${metadata.csrf_token}&flowId=${metadata.flow_id}&flowTime=${metadata.flow_time}`;
        wsRef.current = new WebSocket(connectionUrl);
        wsRef.current.binaryType = 'arraybuffer';

        wsRef.current.onerror = async (e) => {
          const attemptToRefreshCsrf = await csrfRefresh();

          if (attemptToRefreshCsrf && attemptCsrfRefreshRef.current) {
            connectWebsocket(options);
            attemptCsrfRefreshRef.current = false;
          } else {
            reject(e);
          }
        };

        wsRef.current.onmessage = (e) => {
          const data = JSON.parse(e.data);

          // we need to wait for "initSocketIO" from backend
          // to start sending messages.
          if (data.initSocketIO) {
            readyRef.current = true;
            resolve(wsRef.current);
            attemptCsrfRefreshRef.current = true;
          } else subscribersRef.current.forEach((s) => s(data, lastMessage));
        };

        wsRef.current.onclose = (e) => {
          if (e.wasClean || !readyRef.current) {
            // if readyRef.current is false we can assume the reason
            // for `onclose` was an authentication error, so we
            // can skip retrying to connect in this scenario.
            // however if websocket was closed after state.ready
            // was true, we will continue to retry to connect.
            return;
          }

          if (!!reconnectRef.current) {
            clearTimeout(reconnectRef.current);
          }

          // Retry in 5 seconds
          reconnectRef.current = setTimeout(() => {
            try {
              connectWebsocket(options);
            } catch (_) {}

            // retry last search
            setTimeout(() => {
              if (lastMessage.current) {
                sendMessage(lastMessage.current);
              }
            }, 500);
          }, 5000);
        };

        if (options?.enableHeartbeat) heartbeat();

        dispatch({
          type: WebsocketActionType.OPEN_WEB_SOCKET,
          websocket: wsRef.current,
        });
      });
    },
    [heartbeat, sendMessage],
  );

  const subscribeToMessages = useCallback((subscriber: (data: any, lastMessage: any) => void) => {
    subscribersRef.current.push(subscriber);
  }, []);

  return (
    <WebsocketContext.Provider
      value={{
        ...state,
        closeWebsocket,
        connectWebsocket,
        sendMessage,
        subscribeToMessages,
      }}
    >
      {children}
    </WebsocketContext.Provider>
  );
};

import { paths } from '@qatalog/common';
import * as Sentry from '@sentry/browser';
import { Location } from 'history';
import Lockr from 'lockr';
import React, { useCallback } from 'react';
import { Link, matchPath, useHistory } from 'router';

import { Thread } from 'mosaiq/threads/types';
import { Entity } from 'types';

import { CustomEntitySchema } from 'mosaiq/custom-entities/types';
import { useIsExtraSmallOnly } from 'mosaiq/hooks/useMediaQueries';
import { Task } from 'mosaiq/tasks';
import { EntityType } from 'mosaiq/types';
import { useIsGuestApp } from 'store/metadata';

import { showInAppNotification } from './notifications';

export const openExternalLink = (url: string, e?: React.MouseEvent<any> | null, newTab = true) => {
  if (!!newTab || e?.ctrlKey || e?.metaKey) {
    window.open(url, '_blank', 'noopener, noreferrer')?.focus();
    return;
  }

  window.location.href = url;
};

interface EntityLinkProps extends React.HTMLAttributes<HTMLAnchorElement> {
  entity?: Entity;
  entityType: string;
  parentEntity?: Entity;
  parentEntityType?: string;
  linkProps?: Record<string, any>;
}

export const EntityLink = React.forwardRef<HTMLAnchorElement, EntityLinkProps>(
  (
    { children, entity, entityType, parentEntity, parentEntityType, linkProps = {}, ...props },
    ref,
  ): React.ReactElement | null => {
    if (!entity) {
      return null;
    }

    const [pathname, search]: string[] = paths
      .entity(entity, entityType, parentEntity, parentEntityType)
      .split('?');

    if (!pathname || pathname === '//') {
      return !!children ? <span>{children}</span> : null;
    }

    return (
      <Link
        ref={ref}
        to={{
          pathname,
          search,
          ...linkProps,
        }}
        {...props}
      >
        {children}
      </Link>
    );
  },
);

type State = {
  origin?: string;
  renderLocation?: Location;
  fromParent?: boolean;
  tasksNumbers?: number[];
};

export const useEntityNavigation = () => {
  const history = useHistory<State>();
  const isMobileView = useIsExtraSmallOnly();
  const isGuestApp = useIsGuestApp();

  const navigateToEntity = useCallback(
    (
      e?: React.MouseEvent | null,
      entity?: Entity,
      entityType?: string,
      parentEntity?: Entity,
      parentEntityType?: string,
      subEntityType?: string,
      state: State = {},
    ) => {
      if (!entity) return;

      let entityPath = paths.entity(entity, entityType, parentEntity, parentEntityType);

      if (entityType === 'tasks') {
        const task = entity as Task;

        if (task?.number || task?.parent_task?.number) {
          entityPath = paths.entity(task?.parent_task ?? task, entityType);

          if (!isMobileView && !isGuestApp) {
            if (history.location.state?.renderLocation) {
              state.renderLocation = history.location.state.renderLocation;
            } else {
              state.renderLocation = history.location;
            }
          }

          if (parentEntity && parentEntityType) {
            state.fromParent = true;
          }
        } else if (parentEntity && parentEntityType) {
          entityPath = paths.entity(parentEntity, parentEntityType);
        } else {
          return;
        }

        if (!!state?.tasksNumbers) {
          Lockr.set('tasksNumbers', state?.tasksNumbers);
        }
      } else if (entityType === 'measurements' && !!parentEntity) {
        entityPath = paths.entity(parentEntity, parentEntityType);
      } else if (entityType === 'threads') {
        const isDraft = (entity as Thread).is_published === false ? '/edit' : '';
        entityPath = `/threads/${entity.id}${isDraft}`;
      } else if (entityType === 'pages' || entityType === 'workflow_assignments') {
        if (matchPath('/workflows/workflow/:slug/assignments', history.location.pathname)) {
          state.renderLocation = {
            ...history.location.state?.renderLocation,
            pathname: history.location.pathname.replace('/assignments', ''),
          };
        } else {
          state.renderLocation = history.location.state?.renderLocation || history.location;
        }
      }

      if (!entityPath || entityPath === '//') {
        showInAppNotification({
          content: "Sorry, we can't open this link at the moment",
          type: 'error',
        });

        Sentry.captureException(
          new Error(
            `No path found for entity: ${JSON.stringify(
              entity ?? {},
            )} and entityType: ${entityType}`,
          ),
        );

        return;
      }

      entityPath = !!subEntityType ? `${entityPath}/${subEntityType}` : entityPath;

      if (e?.ctrlKey || e?.metaKey) {
        window.open(entityPath, '_blank', 'noopener, noreferrer')?.focus();
        return;
      }

      history.push(entityPath, {
        ...state,
        referrerUrl: history.location,
      });
    },
    [history, isGuestApp, isMobileView],
  );

  const navigateToCustomEntity = useCallback(
    (
      e?: React.MouseEvent | null,
      entity?: Entity,
      schema?: CustomEntitySchema,
      subEntityType?: string,
      params?: Record<string, any>,
      state = {},
    ) => {
      if (!entity) return;

      // TODO: real path utils for custom entities how?
      let entityPath = `/t/${schema?.slug ?? ''}/${entity.id ?? ''}`;

      if (!entityPath || entityPath === '/t//') {
        showInAppNotification({
          content: "Sorry, we can't open this link at the moment",
          type: 'error',
        });

        Sentry.captureException(
          new Error(
            `No path found for entity: ${JSON.stringify(entity ?? {})} and entityType: ${
              schema?.slug ?? 'CUSTOM'
            }`,
          ),
        );

        return;
      }

      entityPath = !!subEntityType ? `${entityPath}/${subEntityType}` : entityPath;

      if (e?.ctrlKey || e?.metaKey) {
        window.open(entityPath, '_blank', 'noopener, noreferrer')?.focus();
        return;
      }

      state = {
        ...(params ?? {}),
        ...(schema?.builtin_type === 'teams' && {
          origin: history.location.pathname,
        }),
      };

      history.push(entityPath, {
        ...state,
        referrerUrl: history.location,
      });
    },
    [history],
  );

  const navigateToOverview = useCallback(
    (e: React.MouseEvent | null, entityType: string, schema?: CustomEntitySchema) => {
      const origin = history.location.state?.origin;

      if (origin) {
        return history.push(origin);
      }

      const overviewPath =
        entityType === EntityType.CUSTOM ? `/t/${schema?.slug}` : paths.entityOverview(entityType);

      if (!overviewPath || overviewPath === '//') {
        showInAppNotification({
          content: "Sorry, we can't open this link at the moment",
          type: 'error',
        });

        Sentry.captureException(new Error(`No path found for entityType: ${entityType}`));

        return;
      }

      if (e?.ctrlKey || e?.metaKey) {
        window.open(overviewPath, '_blank', 'noopener, noreferrer')?.focus();
        return;
      }

      history.push(overviewPath);
    },
    [history],
  );

  return {
    navigateToEntity,
    navigateToCustomEntity,
    navigateToOverview,
    EntityLink,
  };
};

export const getRoutesByRole = ({ routes, role }: Record<string, any>): Record<string, any> => {
  const routesFilter = (route: Record<string, any>) => !route.roles || route.roles.includes(role);

  if (Array.isArray(routes)) {
    return routes.filter(routesFilter);
  }

  return Object.keys(routes)
    .filter((key) => routesFilter(routes[key]))
    .reduce(
      (routesObj, routeKey) => ({
        ...routesObj,
        [routeKey]: routes[routeKey],
      }),
      {},
    );
};

export const isCustomEntityOverview = (schema: CustomEntitySchema): boolean => {
  return !!matchPath(`/t/${schema.slug}/:id`, window.location.pathname);
};

import { Button, Checkbox, Flex, Typography } from '@qatalog/mosaiq-ui';
import { sortBy } from 'lodash';
import { Check, EnvelopeSimple } from 'phosphor-react';
import { useMemo, useState } from 'react';
import Lockr from 'lockr';

import analytics, { useAnalytics } from 'analytics';
import config from 'config';
import { useEffectOnce } from 'hooks';
import { useHistory } from 'router';

import { uncacheMetadata, useMetadataState } from 'store/metadata';
import { AuthMethod } from 'types';
import { translateErrno } from 'utils/errors';
import { capitalizeFirstLetter } from 'utils/helper';
import { showInAppNotification } from 'utils/notifications';
import { dekebab } from 'utils/strings';

import { OnboardingEmail, OnboardingOkta } from '../Onboarding';

import {
  FreeTrialToast,
  LoginContainer,
  LoginContent,
  LoginFooter,
  LoginForm,
  LoginHeader,
  LoginInner,
  LoginLogo,
  LoginShadowButton,
} from './Login.styles';

import OnboardingSupportBtn from 'mosaiq/signup/components/OnboardingSupportBtn/OnboardingSupportBtn';
import CustomDomainAuthentication from './components/CustomDomainAuthentication';
import { ReactComponent as QMonogramWithTextDark } from 'assets/icons/q-monogram-with-text-dark.svg?react';
import { ReactComponent as IconGoogle } from 'assets/icons/login-google.svg?react';
import { ReactComponent as IconMS } from 'assets/icons/login-ms.svg?react';
import { ReactComponent as IconOkta } from 'assets/icons/login-okta.svg?react';
import { OnboardingLogoContainer } from 'mosaiq/signup/components';

interface AuthButton {
  position?: number;
  element: JSX.Element;
}

const Login = () => {
  const history = useHistory();
  const [showEmailOnboarding, setShowEmailOnboarding] = useState<boolean>(false);
  const [showOktaOnboarding, setShowOktaOnboarding] = useState<boolean>(false);
  const [rememberMe, setRememberMe] = useState<boolean>(false);

  const { trackPageView, trackEvent, eventTypes } = useAnalytics();

  const [metadata] = useMetadataState();

  const documentReferrer: string | null = useMemo(() => document.referrer, []);

  const urlSearchParams: URLSearchParams = useMemo(
    () => new URLSearchParams(window.location.search),
    [],
  );

  const qatalogRootUrl: string = useMemo(() => {
    const [part1, part2, part3] = window.location.host.split('.');

    return !part3
      ? `${window.location.protocol}//${part1}.${part2}`
      : `${window.location.protocol}//${part2}.${part3}`;
  }, []);

  const error: string | undefined = useMemo(() => {
    const errno = urlSearchParams.get('errno');
    if (errno) {
      return translateErrno({ errno });
    }
  }, [urlSearchParams]);

  const origin: string | null = useMemo(() => urlSearchParams?.get('origin'), [urlSearchParams]);

  const industry: string | null = useMemo(
    () => urlSearchParams?.get('industry'),
    [urlSearchParams],
  );

  const title: string = useMemo(() => {
    switch (origin) {
      case 'build':
        trackPageView(eventTypes.signup.VISIT_TO_SIGNUP_PAGE_FOR_INDUSTRY_BUILD);
        return !!industry
          ? `Build your Qatalog for ${capitalizeFirstLetter(dekebab(industry))}`
          : 'Build your Qatalog';

      case 'signup':
        trackPageView(eventTypes.signup.VISIT_TO_SIGNUP_PAGE);
        return 'Get started';

      default:
        trackPageView(eventTypes.login.VISIT_LOGIN_PAGE);
        return 'Welcome back!';
    }
  }, [
    eventTypes.login.VISIT_LOGIN_PAGE,
    eventTypes.signup.VISIT_TO_SIGNUP_PAGE,
    eventTypes.signup.VISIT_TO_SIGNUP_PAGE_FOR_INDUSTRY_BUILD,
    trackPageView,
    urlSearchParams,
  ]);

  const keyword = origin === 'signup' ? 'Sign up' : 'Continue';

  useEffectOnce(() => {
    analytics.identify(metadata);
    saveMarketingData();
  });

  const handleAuth = (redirectPath: string) => {
    const url = new URL(`${window.location.origin}${redirectPath}`);
    if (rememberMe) url?.searchParams?.set('persist', 'true');

    // @ts-ignore
    window.location = url.toString();
  };

  const saveMarketingData = () => {
    if (documentReferrer && documentReferrer !== '')
      Lockr.set('marketing_data_key', documentReferrer);
  };

  const authMethods: AuthMethod[] = useMemo(
    () => metadata.org?.auth_methods || ['email', 'google', 'microsoft'],
    [metadata.org],
  );

  const authButtons: Record<AuthMethod, AuthButton> = useMemo(
    () => ({
      email: {
        element: (
          <LoginShadowButton
            data-testid="loginWithEmailBtn"
            key="email"
            size="large"
            variant="outline"
            active
            startAdornment={<EnvelopeSimple weight="bold" size={18} />}
            onClick={() => {
              trackEvent(eventTypes.signup.CONTINUE_WITH_EMAIL_CLICKED);
              setShowEmailOnboarding(true);
            }}
          >
            {keyword} with email
          </LoginShadowButton>
        ),
      },
      google: {
        position: 0,
        element: (
          <Button
            key="google"
            size="large"
            startAdornment={<IconGoogle width={18} height={18} />}
            onClick={async () => {
              trackEvent(eventTypes.signup.CONTINUE_WITH_GOOGLE_CLICKED);
              handleAuth(config.envConfig.endpoints.auth.googleOAuth);
            }}
          >
            {keyword} with Google
          </Button>
        ),
      },
      microsoft: {
        position: 1,
        element: (
          <Button
            key="microsoft"
            size="large"
            startAdornment={<IconMS width={18} height={18} />}
            onClick={() => {
              trackEvent(eventTypes.signup.CONTINUE_WITH_MICROSOFT_CLICKED);
              handleAuth(config.envConfig.endpoints.auth.microsoftOAuth);
            }}
          >
            {keyword} with Microsoft
          </Button>
        ),
      },
      okta: {
        position: 2,
        element: (
          <Button
            key="okta"
            size="large"
            startAdornment={<IconOkta width={18} height={18} />}
            onClick={() => {
              trackEvent(eventTypes.signup.CONTINUE_WITH_OKTA_CLICKED);
              metadata.org?.integrations_data?.okta?.domain
                ? handleAuth(config.envConfig.endpoints.auth.oktaOAuth)
                : setShowOktaOnboarding(true);
            }}
            data-testid="loginOktaBtn"
          >
            {keyword} with Okta
          </Button>
        ),
      },
    }),
    [metadata.org?.integrations_data?.okta?.domain, rememberMe],
  );

  const activeAuthButtons = useMemo(
    () =>
      sortBy(
        authMethods.filter((m) => !!authButtons[m]).map((m) => ({ key: m, ...authButtons[m] })),
        [(b) => b.position, (b) => b.key],
      ).map((b) => b.element),
    [authButtons, authMethods],
  );

  if (showEmailOnboarding) {
    return <OnboardingEmail rememberMe={rememberMe} companyOnly={origin === 'signup'} />;
  }

  if (showOktaOnboarding) {
    return <OnboardingOkta rememberMe={rememberMe} />;
  }

  if (error) {
    showInAppNotification({
      content: error,
      config: {
        autoClose: 5000,
        position: 'bottom-left',
      },
    });
  }

  // Ensure new sessions never pick up old metadata
  uncacheMetadata();

  return (
    <CustomDomainAuthentication>
      <LoginContainer data-testid="loginContainer">
        <LoginInner>
          {history.location.search.includes('free-trial') && (
            <FreeTrialToast
              message="Your free trial has been enabled."
              icon={<Check size="16" />}
            />
          )}
          <LoginLogo href={qatalogRootUrl} title="Back to homepage">
            {metadata.org?.logo_url ? (
              <img alt="logo" src={metadata.org?.logo_url} />
            ) : (
              <QMonogramWithTextDark />
            )}
          </LoginLogo>
          <LoginContent>
            <OnboardingLogoContainer paddingTop={28} marginTop={-28}>
              <LoginForm>
                <LoginHeader>{title}</LoginHeader>
                <Typography variant="body2" color="body">
                  {origin === 'signup'
                    ? 'Sign up using your work email address to continue'
                    : 'Select your preferred login method to continue'}
                </Typography>

                <Flex marginTop={4} gap={2} direction="column">
                  {activeAuthButtons}

                  <Checkbox
                    checked={rememberMe}
                    onChange={() => setRememberMe(!rememberMe)}
                    label="Remember me for 28 days"
                    labelProps={{ variant: 'body3' }}
                  />
                </Flex>
              </LoginForm>
            </OnboardingLogoContainer>
          </LoginContent>
          <LoginFooter>
            <OnboardingSupportBtn />
          </LoginFooter>
        </LoginInner>
      </LoginContainer>
    </CustomDomainAuthentication>
  );
};

export default Login;

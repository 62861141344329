import { lazy, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from 'redux-store';
import { BrowserRouter } from 'router';
import axios from 'axios';
import Lockr from 'lockr';
import { ApolloProvider } from '@apollo/client';
import OpenReplay, { Options as OpenReplayOptions } from '@openreplay/tracker';
import { StartOptions } from '@openreplay/tracker/lib/app';
import * as Sentry from '@sentry/browser';
import { ToastContainer } from 'react-toastify';
import { Integrations } from '@sentry/tracing';

import { GlobalStyle, MosaiqThemeProvider } from '@qatalog/mosaiq-ui';
import '@qatalog/mosaiq-ui/dist/fonts.css';

// eslint-disable-next-line qatalog/datetime-helpers
import { format } from 'date-fns';

import analytics, { eventTypes } from 'analytics';
import config from 'config';
import { StatsigWrapper } from 'feature-flags';
import { integrationInterceptor } from 'integrations';
import theme, { ExternalLibStyles } from 'theme';

import client, { clientVersionInterceptor, initApolloClientRefetch } from 'utils/client';
import {
  captureErrorOnSentry,
  handleApiError,
  handleRequestError,
  promiseRejectionEventToError,
  silenceError,
} from 'utils/errors';

import { isDevelopment, isIE } from 'utils/feature-detection';

import LocalState from 'store/localState';
import {
  MetadataProvider,
  useMetadataState,
  cacheMetadata,
  readMetadata,
  readStaticMetadata,
  SessionData,
  StaticSessionData,
  Session,
} from 'store/metadata';

import { QuickSearchProvider } from 'mosaiq/search';
import { ModalsProvider } from 'mosaiq/modal';
import { NotificationsProvider } from 'mosaiq/notifications';
import { NavSidebarProvider } from 'mosaiq/components/NavSidebar';
import { WebsocketProvider } from 'mosaiq/websockets';
import { UnauthedApp } from 'mosaiq/signup';
import { useIsExtraSmallOnly } from 'mosaiq/hooks';
import { personFlags } from 'mosaiq/utils';

import ErrorBoundary from 'components/ErrorBoundary';
import LoadingApp from 'components/LoadingApp';
import { ScrollLockProvider } from 'utils/overlays';
import { Step as FreeTrialStep } from 'mosaiq/signup/views/FreeTrialSignup/types';
import { setCsrfHeaders } from 'utils/setCsrfHeaders';

const App = lazy(() => import('App'));
const GuestApp = lazy(() => import('mosaiq/guest/views/GuestApp'));
const ResponsiveApp = lazy(() => import('responsive/App'));
const Setup = lazy(() => import('mosaiq/signup/views/Setup'));
const Onboarding = lazy(() => import('mosaiq/signup/views/Onboarding'));
const FreeTrialSignup = lazy(() => import('mosaiq/signup/views/FreeTrialSignup'));

main();

async function main() {
  interceptUnsupportedUserAgents();

  Lockr.prefix = 'qatalog_';

  const staticMetadata = readStaticMetadata();
  Lockr.set('client_version', staticMetadata.client_version as string);

  setCsrfHeaders(staticMetadata?.csrf_token);

  let metadata: Partial<SessionData> = {};
  try {
    metadata = (await readMetadata<'unknown'>()) ?? {};
  } catch (_) {}

  initApolloClientRefetch(client);
  initSentry(metadata);
  initOpenReplay(metadata);
  initDefaultHeaders(metadata);
  integrationInterceptor();
  initAxiosInterceptors();
  initUnhandledPromiseInterceptor();

  cacheMetadata(metadata);

  const root = createRoot(document.getElementById('root')!);

  root.render(
    <BrowserRouter>
      <MetadataProvider>
        <StatsigWrapper>
          <ApolloProvider client={client}>
            <LocalState>
              <Provider store={store}>
                <ScrollLockProvider>
                  <MosaiqThemeProvider extras={theme}>
                    <GlobalStyle />
                    <ExternalLibStyles />
                    <WebsocketProvider>
                      <ModalsProvider>
                        <NotificationsProvider>
                          <NavSidebarProvider>
                            <QuickSearchProvider>
                              <ErrorBoundary>
                                <ToastContainer
                                  position="bottom-left"
                                  autoClose={4000}
                                  newestOnTop={false}
                                  closeOnClick={false}
                                  rtl={false}
                                  draggable={false}
                                  closeButton={false}
                                  pauseOnHover={true}
                                  icon={false}
                                />
                                <Suspense fallback={<LoadingApp />}>
                                  <RenderComponent />
                                </Suspense>
                              </ErrorBoundary>
                            </QuickSearchProvider>
                          </NavSidebarProvider>
                        </NotificationsProvider>
                      </ModalsProvider>
                    </WebsocketProvider>
                  </MosaiqThemeProvider>
                </ScrollLockProvider>
              </Provider>
            </LocalState>
          </ApolloProvider>
        </StatsigWrapper>
      </MetadataProvider>
    </BrowserRouter>,
  );
}

const RenderComponent = () => {
  const mobileView = useIsExtraSmallOnly();
  const [metadata] = useMetadataState<'unknown'>();

  if (['app', 'settings'].includes(metadata.render_page!) && !!metadata.is_authenticated) {
    if (!metadata.is_anon && !metadata.person?.flags?.[personFlags.onboarding.setup])
      return <Setup />;
    if (metadata.person?.org_role === 'guest') return <GuestApp />;
    if (mobileView) return <ResponsiveApp />;
    return <App />;
  }

  if (metadata.is_authenticated) {
    // send user to either Free Trial or classic Onboarding flow
    if (
      Object.values(FreeTrialStep).includes(
        (metadata as Session<'onboarding'>).current_step as FreeTrialStep,
      )
    ) {
      return (
        <Suspense fallback={null}>
          <FreeTrialSignup />
        </Suspense>
      );
    } else {
      return (
        <Suspense fallback={null}>
          <Onboarding />
        </Suspense>
      );
    }
  }

  return <UnauthedApp />;
};

function initSentry(metadata?: Partial<SessionData>) {
  if (!metadata?.sentry) return;

  metadata.sentry.beforeSend = (event: any, hint: any) => {
    const error = hint.originalException;

    if (error === 'Timeout') return null;

    if (error?.__sentry_xhr__?.status_code === 0) return null;

    if (!error || !error.message) return event;
    for (const re of config.systemConfig.ignoredExceptionMessages) {
      if (error.message.match(re)) return null;
    }

    return event;
  };

  if (metadata.client_name && metadata.client_version) {
    metadata.sentry.release = `${metadata.client_name}@${metadata.client_version}`;
  }

  metadata.sentry.integrations = [new Integrations.BrowserTracing()];
  Sentry.init({ ...metadata.sentry, denyUrls: [...config.systemConfig.ignoredExceptionUrls] });
  Sentry.setTag('origin', 'web-client');

  if (!metadata.person) return;

  Sentry.configureScope((scope) => {
    scope.setTag('flow_id', metadata.flow_id);
    scope.setUser({
      id: metadata.person!.id,
    });
  });
}

function initOpenReplay(metadata: Partial<SessionData>) {
  if (isDevelopment() || !metadata.openreplay_tracker_id) return;
  const initParams: OpenReplayOptions = {
    projectKey: metadata.openreplay_tracker_id,
  };

  const startParams: StartOptions = {
    // @ts-ignore
    revId: `${metadata.client_name}@${metadata.client_version as string}`,
    metadata: {
      apiId: metadata.api_version as string,
    },
  };

  if (metadata?.person) {
    startParams.userID = metadata.person.id;
  }

  if (metadata?.org?.id) {
    startParams.metadata!.orgId = metadata.org.id;
  }

  if (startParams.userID || startParams.metadata!.orgId) {
    const currHour = format(new Date(), 'yyyy-DDD-HH');
    const sessionToken = `${startParams.userID || startParams.metadata!.orgId}-${currHour}`;

    initParams.sessionToken = sessionToken;
  }

  const tracker = new OpenReplay(initParams);
  tracker.start(startParams).then(() => {
    //these tags must be camel cased as per the openreplay docs
    Sentry.setTag('openReplaySessionURL', tracker.getSessionURL());
  });

  Sentry.setTag('openReplaySessionToken', tracker.getSessionToken());

  // prettier-ignore
  axios.defaults.headers.delete['X-OpenReplay-SessionToken'] =
    axios.defaults.headers.get['X-OpenReplay-SessionToken'] =
    axios.defaults.headers.patch['X-OpenReplay-SessionToken'] =
    axios.defaults.headers.post['X-OpenReplay-SessionToken'] =
    axios.defaults.headers.put['X-OpenReplay-SessionToken'] = tracker.getSessionToken()!;
}

function initDefaultHeaders(metadata: Partial<SessionData>) {
  // prettier-ignore
  axios.defaults.headers.delete['X-Qatalog-Flow-Id'] =
    axios.defaults.headers.get['X-Qatalog-Flow-Id'] =
    axios.defaults.headers.patch['X-Qatalog-Flow-Id'] =
    axios.defaults.headers.post['X-Qatalog-Flow-Id'] =
    axios.defaults.headers.put['X-Qatalog-Flow-Id'] = metadata.flow_id!;

  // prettier-ignore
  axios.defaults.headers.delete['X-Qatalog-Flow-Time'] =
    axios.defaults.headers.get['X-Qatalog-Flow-Time'] =
    axios.defaults.headers.patch['X-Qatalog-Flow-Time'] =
    axios.defaults.headers.post['X-Qatalog-Flow-Time'] =
    axios.defaults.headers.put['X-Qatalog-Flow-Time'] = metadata.flow_time!;
}

function initAxiosInterceptors() {
  axios.interceptors.response.use(
    (response) => {
      clientVersionInterceptor(response?.headers?.['x-qatalog-client-version']);
      return response;
    },
    (error) => {
      if (silenceError(error)) {
        Sentry.setTag('not-ux-blocking', null);
        captureErrorOnSentry(error);
      } else if (error.code === 'ERR_CANCELED' || error.code === 'ECONNABORTED') {
        return;
      } else {
        const { errno, params, message, ...data } = error.response?.data || {};
        handleRequestError({
          statusCode: error.response?.status,
          data,
          errno,
          params,
          message,
          retryFn: () => axios(error.config),
          handleFn: () => handleApiError(error.response),
          config: error.response?.config,
        });

        return Promise.reject(error.response);
      }
    },
  );
}

function initUnhandledPromiseInterceptor() {
  window.onunhandledrejection = (event) => {
    const error = promiseRejectionEventToError(event);
    captureErrorOnSentry(error);
    event.preventDefault();
  };
}

function interceptUnsupportedUserAgents() {
  if (isIE()) {
    analytics.track(eventTypes.support.UNSUPPORTED_BROWSER, { ua: navigator.userAgent });

    if (
      window.confirm('Your browser is outdated, please install a modern browser to use Qatalog')
    ) {
      window.location.href = 'https://qatalog.com';
    }
  }
}

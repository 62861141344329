export enum FormStep {
  USER_DETAILS = 'userDetails',
  INSTANCE_TYPE = 'instanceType',
}

export enum Step {
  SIGNUP = 'freeTrialSignupOrg',
  PENDING = 'freeTrialRequested',
  REJECTED = 'freeTrialRejected',
  APPROVED = 'freeTrialApproved',
}

export type FormFields = {
  orgName: string;
  orgSubdomain: string;
  companySize: string;
  useCasesText: string;
  jobFunction: string;
  jobRole: string;
  preferredInstanceType: string;
  acceptTerms: boolean;
};

const config = {
  env: process.env.NODE_ENV,
  showError: true,
  // List of error codes that we don't want to log to sentry
  // See /packages/api/src/error.js
  sentryExcludedErrorCodes: [
    102,
    105,
    107,
    108,
    109,
    111,
    114,
    115,
    116,
    117,
    119,
    123,
    125,
    126,
    127,
    128,
    130,
    131,
    132,
    136,
    138,
    139,
    140,
    141,
    142,
    149,
    155,
    156,
    157,
    179,
    180,
    // hasura errors
    'uq_invite_org_email_address',
    'uq_tags_name',
    'uq_groups_name',
    'uq_locations_name',
    'uq_departments_name',
    'uq_country_name',
    'uq_country_code',
    'uq_orgs_stripe_customer_id',
    'uq_org_domain',
    'uq_org_cname',
    'uq_teams_name',
    'uq_projects_name',
  ],
  endpoints: {
    exports: {
      dashboard: '/api/exports',
    },
    admins: {
      billingAdmins: '/api/org/email-admins',
    },
    activities: {
      entity: '/api/activities',
    },
    approvals: {
      request: '/api/approvals',
      approve: '/api/approvals/{approval_id}/approve',
      reject: '/api/approvals/{approval_id}/reject',
    },
    assistant: {
      downloadCsv: '/assistant-sessions/{#assistantSessionId}/download/{#searchResultId}',
      feedback: '/api/assistant-sessions/feedback',
      mapping: {
        create: '/api/assistant-mappings',
        update: '/api/assistant-mappings/{#id}',
        delete: '/api/assistant-mappings/{#id}',
      },
    },
    auth: {
      csrf: '/auth/csrf',
      customDomainAuthentication: '/api/auth/custom-domain',
      email: '/auth/email',
      resendEmail: '/api/auth/email/resend',
      googleOAuth: '/oauth-google',
      microsoftOAuth: '/oauth-microsoft',
      oktaOAuth: '/oauth-okta',
      isAuthenticated: '/isauthenticated',
    },
    billing: {
      currentPlan: '/api/billing/plans/current',
      plans: '/api/billing/plans',
      session: '/api/billing/session',
      changePlan: '/api/billing/session/plan',
      cancel: '/api/billing/subscription/cancel',
    },
    comments: {
      create: '/api/comments',
      update: '/api/comments/{#comment_id}',
      delete: '/api/comments/{#comment_id}',
      resolve: '/api/comments/{#comment_id}/resolve',
      unresolve: '/api/comments/{#comment_id}/unresolve',
    },
    customSources: {
      update: '/api/custom-sources',
      delete: '/api/custom-sources/{#id}',
    },
    ecosystem: {
      orchestration: {
        mimecast: {
          download: '/ecosystem/mimecast/download/file',
        },
        providerType: '/api/ecosystem/orchestration/{#provider}/{#type}',
      },
      mapping: {
        create: '/api/ecosystem/mapping',
        update: '/api/ecosystem/mappings/{#entityId}/data',
        get: '/api/ecosystem/mappings?entityId={#entityId}',
        delete: '/api/ecosystem/mapping?mappingId={#mappingId}',
      },
      auth: {
        mimecast: '/api/ecosystem/mimecast',
        token: `/api/ecosystem/{#provider}/token`,
        signInToken: `/api/ecosystem/{#provider}/sign-in-token`,
      },
      import: '/api/ecosystem/import',
      share: '/api/ecosystem/share',
    },
    entities: {
      archive: '/api/entities/{#entityId}/archive',
      collaborators: {
        update: '/api/entities/{#entityId}/collaborators',
        delete: '/api/entities/{#entityId}/collaborators',
        deleteUser: '/api/entities/{#entityId}/collaborators/{#collaboratorId}',
      },
      instances: {
        create: '/api/entities/instances',
        update: '/api/entities/instances/{#entityId}',
        delete: '/api/entities/instances/{#entityId}',
        bulkDelete: '/api/entities/instances',
        bulkArchive: '/api/entities/archive',
        import: '/api/entities/import',
        override: '/api/entities/instances/override',
      },
      share: {
        create: '/api/external',
        delete: '/api/external/{#inviteId}',
      },
      tags: {
        create: '/api/entities/{#entityId}/tags',
        delete: '/api/entities/{#entityId}/tags/{#tagId}',
      },
      types: {
        create: '/api/entities/types',
        update: '/api/entities/types/{#id}',
        delete: '/api/entities/types/{#id}',
      },
      visibility: '/api/entities/{#entityId}/visibility',
    },
    events: '/api/analytics',
    file: '/api/file',
    measurements: {
      create: '/api/measurements',
      update: '/api/measurements/{#id}',
      updateValue: '/api/measurements/{#id}/values',
      addDependee: '/api/measurements/{#id}/dependees',
      deleteDependee: '/api/measurements/{#id}/dependees/{#dependee_id}',
    },
    modules: {
      generate: '/api/modules/generate',
    },
    examples: {
      generate: '/api/modules/examples/generate',
    },
    externalApi: {
      createKey: '/api/external-api/keys',
      revokeKey: '/api/external-api/keys/{#id}',
    },
    graphql: '/api/graphql',
    images: { upload: '/api/image' },
    integrations: {
      get: '/api/integrations',
      auth: '/integrations/{#app}/auth',
      authCustomClient: '/integrations/{#app}/auth/custom-client',
      authCustomSubdomain: '/integrations/{#app}/auth/custom-subdomain',
      disconnect: '/integrations/{#app}/disconnect',
      block: '/api/org/integrations',
      unblock: '/api/org/integrations',
      required: '/api/org/required-integrations',
      settings: '/api/org-integration-settings',
    },
    invite: {
      cancel: '/api/invite/cancel',
      resend: '/api/invite/resend',
      send: '/api/invite',
    },
    kudos: {
      create: '/api/kudos',
      delete: '/api/kudos/{#id}',
      thanks: '/api/kudos/{#id}/thanks',
    },
    locations: {
      create: '/api/locations',
      delete: '/api/locations/{#id}',
    },
    metrics: '/metrics',
    milestones: {
      create: '/api/milestones',
      update: '/api/milestones/{#id}',
      delete: '/api/milestones/{#id}',
    },
    notifications: {
      the: '/api/notifications',
      seen: '/api/notifications/seen',
      foryou: '/api/notifications/for-you',
    },
    signupV2: {
      demoForm: '/demo/?source=onboarding&email={#email}',
      domainCheck: '/api/signup/domain/check',
      orgPicker: '/signup/org/{#orgId}?flow=refined',
      orgRevert: '/api/signup/revert?flow=refined',
      org: '/api/signup/org?flow=refined',
      suggestSubdomain: '/api/signup/subdomain/suggest?flow=refined',
      finish: '/signup/finish?flow=refined',
      freeTrial: '/api/signup/org/free-trial',
    },
    org: {
      the: '/api/org',
      roles: '/api/org/roles',
      flags: '/api/org/flags',
      domainRestriction: '/api/org/domain-restriction',
      reset: '/api/org/{#id}',
    },
    pages: {
      create: '/api/pages',
      duplicate: '/api/pages/{#pageId}/duplicate',
      ydoc: '/api/pages/{page_id}/ydoc',
      import: '/api/pages/import',
    },
    people: {
      update: '/api/person',
      flags: '/api/person/flags',
      deactivate: '/api/person/{#id}/archive',
    },
    pins: {
      create: '/api/pins',
      get: '/api/pins/{#id}',
    },
    quickViews: {
      create: '/api/quick-views',
      edit: '/api/quick-views/{#id}',
      delete: '/api/quick-views/{#id}',
    },
    reactions: '/api/reactions',
    resources: {
      create: '/api/resources',
      getUrlMetadata: '/api/resources/get-metadata',
      delete: '/api/resources/{#id}',
      edit: '/api/resources/{#id}',
    },
    search: {
      execute: '/api/search',
      autocomplete: '/api/search/autocomplete',
      queryHistory: '/api/search/history',
      resultHistory: '/api/search/result-history',
    },
    sessions: { the: '/api/session', all: '/api/sessions', reset: '/session/reset' },
    settings: {
      notifications: '/api/settings/notifications/{#channel}',
    },
    subscriptions: {
      create: '/api/subscriptions',
      delete: '/api/subscriptions/{#id}',
    },
    suggestedQueries: {
      create: '/api/suggested-queries',
      delete: '/api/suggested-queries/{#id}',
    },
    tags: {
      create: '/api/tags',
      delete: '/api/tags/{id}',
    },
    teams: {
      create: '/api/teams',
      update: '/api/teams/{#id}',
      delete: '/api/teams/{#id}',
      redirect: '/api/teams/{#slug}',
    },
    threads: {
      create: '/api/threads',
      update: '/api/threads/{#id}',
      delete: '/api/threads/{#id}',
      resolve: '/api/threads/{#id}/resolve',
      unresolve: '/api/threads/{#id}/unresolve',
      items: {
        create: '/api/threads/{#id}/items',
        update: '/api/threads/{#id}/items/{#itemId}',
        delete: '/api/threads/{#id}/items/{#itemId}',
      },
    },
    triggers: {
      create: '/api/triggers',
      update: '/api/triggers/{#id}',
      delete: '/api/triggers/{#id}',
    },
    workflows: {
      duplicate: '/api/workflows/{#id}/duplicate',
      create: '/api/workflows',
      update: '/api/workflows/{#id}',
      draft: '/api/workflows/{#id}/draft_items',
      draft_reorder: '/api/workflows/{#id}/draft_items/reorder',
      draft_item: '/api/workflows/{#id}/draft_items/{#itemId}',
      publish: '/api/workflows/{#id}/publish',
      discard: '/api/workflows/{#id}/discard',
      delete: '/api/workflows/{#id}',
      downloadAll: '/workflows/{#workflowId}/results/all',
      useTemplate: '/api/workflows/{#workflowId}/use-template',
    },
    workflowAssignments: {
      create: '/api/workflow_assignments',
      create_searchflow: '/api/searchflow_assignments',
      update: '/api/workflow_assignments/{#id}',
      item: '/api/workflow_assignments/{#id}/items/{#itemId}',
      reassign: '/api/workflow_assignments/{#id}/items/{#itemId}/reassign',
      incomplete: '/api/workflow_assignments/{#id}/items/{#itemId}/incomplete',
      start: '/api/workflow_assignments/{#id}/start',
      delete: '/api/workflow_assignments/{#id}',
      share_item: '/api/share/workflow_assignments/{#id}/items/{#itemId}',
    },
    tasks: {
      default: '/api/tasks',
      delete: '/api/tasks/{#id}',
    },
    content_templates: {
      create: '/api/content-templates',
      update: '/api/content-templates/{#id}',
      delete: '/api/content-templates/{#id}',
    },
    relationships: {
      connect: '/api/relationships',
      disconnect: '/api/relationships',
    },
    navigationItems: {
      create: '/api/navigation-items',
      update: '/api/navigation-items/{#id}',
      delete: '/api/navigation-items/{#id}',
    },
    views: {
      create: '/api/views',
      update: '/api/views/{#id}',
      delete: '/api/views/{#id}',
    },
  },
} as const;

export default config;
